import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ListDetails } from "@src/app/features/types/option.type";
import { ProductDetails } from "../views/shop-products/product-details/product-details.model";
import { Brand, Category, Product, Tag } from "./categories.type";

@Injectable({
  providedIn: 'root',
})
export class CategoriesRepository {
  public constructor(
    private readonly httpClient: HttpClient,
  ) {}

  public getProducts(params: HttpParams): Observable<ListDetails<Product>> {
    return this.httpClient.get<ListDetails<Product>>('/web/products', {
      params,
    });
  }

  public getCategories(): Observable<Category[]> {
    return this.httpClient.get<Category[]>('/web/categories');
  }

  public getSubcategories(params: HttpParams): Observable<ListDetails<Category>> {
    return this.httpClient.get<ListDetails<Category>>('/web/categories/subcategories', { params });
  }

  public getBrands(params: HttpParams): Observable<ListDetails<Brand>> {
    return this.httpClient.get<ListDetails<Brand>>('/web/brands', { params });
  }

  public getTags(params: HttpParams): Observable<ListDetails<Tag>> {
    return this.httpClient.get<ListDetails<Tag>>('/web/tags', { params });
  }

  public getProduct(id: number): Observable<ProductDetails> {
    return this.httpClient.get<ProductDetails>(`/web/products/${id}`);
  }

  public getPublicProduct(id: number): Observable<ProductDetails> {
    return this.httpClient.get<ProductDetails>(`/web/products/${id}/public`);
  }

  public getFavoriteProducts(params: any): Observable<ListDetails<Product>> {
    return this.httpClient.get<ListDetails<Product>>('/favorite-products', { params });
  }

  public postFavoriteProduct(productId: number): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>('/favorite-products', {
      "productId": productId,
    });
  }

  public removeFavoriteProduct(productId: number): Observable<{ message: string }> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { "productId": productId },
    };
    return this.httpClient.delete<{ message: string }>('/favorite-products', options);
  }
}
